import { useEffect } from 'react';
import { useRouter } from 'next/router';

const Custom404 = () => {
    const router = useRouter();

    useEffect(() => {
        router.push('/error?code=404');
    });

    return null;
};

export default Custom404;
